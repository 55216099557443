import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  render() {
    return (
      <Layout>
        <Navbar page='story' />
          <div className="container">
          <div className="content story-page-div">
            <h1 className="page-header">
              ~ My Story ~
            </h1>

            {/* About */}
            {/* <div className="columns is-centered">
              <div class="notification is-primary is-light summary-div column is-two-thirds">
                I'm currently interested in part-time jobs or internships in the domain of
                backend development or systems programming. Feel free to <Link className="link" to="/contact">hit me up</Link> for any opportunity!

              </div>
            </div> */}

            {/* Employment */}
            <div>
              <h2 className="page-sub-header">
                Work Experience
              </h2>
              <div class="task-container columns is-centered is-multiline">
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content has-background-success-light">
                      <b>Software Engineer L3 (Infrastructure)</b> <br />
                      <small><i>June 2023 - Today</i></small><br />
                      <b>Company</b>: <a href='https://chaldal.com/'>Chaldal</a><br />
                      <b>Location</b>: Dhaka, Bangladesh<br />
                      <b>Responsibilities</b>: <br />
                      <ul className='card-list'>
                        <li>Migrated inter-service communication to gRPC/Protobuf over mTLS for efficient RPC and reducing Service Fabric dependencies</li>
                        <li>Built a high-performance job runner for notifications using Redis & Lua scripts with anti-spam checking, horizontal scalability and 2.5-4x increase from baseline performance</li>
                        <li>Creating authentication systems for securing internal applications using SSO and 2FA which integrates with Active Directory and OpenVPN</li>
                        <li>Maintaining CI/CD pipelines on Azure DevOps; orchestration using Service Fabric; networks using Pfsense, Cloudflare & Traefik</li>
                        <li>Participating in on-call rotations, debugging and mitigating issues in production</li>
                      </ul>
                      <div className="tags-body">
                        <span className="tag">.NET (C# / F#)</span>
                        <span className="tag">SQL Server</span>
                        <span className="tag">Service Fabric</span>
                        <span className="tag">ASP.NET</span>
                        <span className="tag">Azure DevOps</span>
                        <span className="tag">gRPC</span>
                        <span className="tag">Redis</span>
                        <span className="tag">Active Directory</span>
                        <span className="tag">Pfsense</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Backend Engineer (Part-time)</b> <br />
                      <small><i>Jan 2023 - May 2023</i></small><br />
                      <b>Company</b>: <a href='https://www.terolabs.org/'>Tero Labs LLC</a><br />
                      <b>Location</b>: California, United States (Remote)<br />
                      <b>Responsibilities</b>: <br />
                      <ul className='card-list'>
                        <li>Worked on Node.js Express backend with a PostgreSQL database</li>
                        <li>Maintained infrastructure on AWS (S3, EC2, RDS)</li>
                        <li>Created & maintained CI/CD pipeline using Github Actions</li>
                        <li>Handled integration with services like Stripe and AWS SES.</li>
                        <li>Worked in an agile team with daily standups, weekly client calls & bi-weekly sprints</li>
                      </ul>
                      <div className="tags-body">
                        <span className="tag">Node.js</span>
                        <span className="tag">Express.js</span>
                        <span className="tag">PostgreSQL</span>
                        <span className="tag">Stripe</span>
                        <span className="tag">AWS</span>
                      </div>
                    </div>
                    <div class="card-content">
                      <b>Blockchain Engineer (Part-time)</b> <br />
                      <small><i>May 2022 - Dec 2022</i></small><br />
                      <b>Company</b>: <a href='https://www.terolabs.org/'>Tero Labs LLC</a><br />
                      <b>Location</b>: California, United States (Remote)<br />
                      <b>Responsibilities</b>: <br />
                      <ul className='card-list'>
                        <li>Develop & maintain Smart Contract using Solidity from scratch to production</li>
                        <li>Built ERC-standard tokens & marketplaces on the Celo Blockchain</li>
                        <li>Developed testing & deployment pipeline using Hardhat</li>
                        <li>Integrate Smart Contracts to the backend using Web3.js</li>
                        <li>Integrate services like <a href="https://www.fireblocks.com/" target='_blank'>Fireblocks</a> (custodial wallets) and <a href="https://nft.storage/" target='_blank'>NFT.Storage</a> (decentralized storage)</li>
                      </ul>
                      <div className="tags-body">
                        <span className="tag">Solidity</span>
                        <span className="tag">Web3.js</span>
                        <span className="tag">Hardhat</span>
                        <span className="tag">Celo Blockchain</span>
                        <span className="tag">IPFS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Backend Developer (Part-time)</b> <br />
                      <small><i>Dec 2021 - Feb 2023</i></small><br />
                      <b>Company</b>: <a href='https://iict.buet.ac.bd/'>IICT, BUET</a><br />
                      <b>Location</b>: Dhaka, Bangladesh (Hybrid)<br />
                      <b>Responsibilities</b>: <br />
                      <ul className='card-list'>
                        <li>Developed an Online Portal for Advance Payment Application with a focus on transparency, logging & usability</li>
                        <li>Worked with clients directly to brainstorm processes, gather requirements and provide hands-on demonstrations</li>
                        <li>Developed the backend using Python & Django REST Framework from scratch to production</li>
                        <li>Deployed & maintained the system on baremetal on-premise Linux server</li>
                      </ul>
                      <div className="tags-body">
                        <span className="tag">Django-REST-Framework</span>
                        <span className="tag">PostgreSQL</span>
                        <span className="tag">Linux</span>
                        <span className="tag">Docker</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Fullstack Developer (Part-time)</b> <br />
                      <small><i>Feb 2021 - Sep 2021</i></small><br />
                      <b>Company</b>: Dunhill Ltd. (<i>E-Commerce startup based on Dhaka</i>) <br />
                      <b>Project</b>: An E-Commerce platform for small business owners who are unable to afford scalable IT infrastructure and staff<br />
                      <b>Roles</b>: Backend dev , Database design & migration , Server administration , Containerization<br />
                      <div className="tags-body">
                        <span className="tag">Flask</span>
                        <span className="tag">Sqlalchemy</span>
                        <span className="tag">Mysql</span>
                        <span className="tag">Linux</span>
                        <span className="tag">AWS</span>
                        <span className="tag">Docker</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Backend Developer (Freelance)</b> <br />
                      <small><i>May 2020 - Aug 2020</i></small> <br />
                      <b>Company</b>: Nature Food Ltd. (<i>Eco-friendly packaged gourmet food store based on Dhaka</i>) <br />
                      <b>Project</b>: Internal business management dashboard for sales, finance & human-resource<br />
                      <b>Roles</b>: API dev , Database design , Server administration<br />
                      <div className="tags-body">
                        <span className="tag">Django-REST-Framework</span>
                        <span className="tag">PostgreSQL</span>
                        <span className="tag">React</span>
                        <span className="tag">Linux</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Backend Developer (Freelance)</b> <br />
                      <small><i>Oct 2018 - Jan 2019</i></small> <br />
                      <b>Company</b>: S Cubed Technology Ltd. (<i>Tech innovation company that specializes in service automation and intelligent systems</i>) <br />
                      <b>Project</b>: Internal business automation software for a garments manufacturer <br />
                      <b>Roles</b>: Backend dev , Database design<br />
                      <div className="tags-body">
                        <span className="tag">Django</span>
                        <span className="tag">Bootstrap</span>
                        <span className="tag">Linux</span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <br />

            {/* Education */}
            <div>
              <h2 className="page-sub-header">
                Education
              </h2>
              <div class="task-container columns is-centered is-multiline">
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Computer Science & Engineering (B.Sc.)</b> <br />
                      <b>Bangladesh University of Engineering & Technology</b> <a href='https://www.buet.ac.bd/web/'>🌐</a> <br />
                      <small><i>Mar 2018 - May 2023</i></small><br />
                      <b>Grade</b>: 3.82 (Dean's List Award, University Merit Scholarship)<br />
                    </div>
                  </div>
                </div>
                <div className="column is-two-thirds">
                  <div class="card">
                    <div class="card-content">
                      <b>Higher Secondary Certificate (HSC)</b> <br />
                      <b>Notre Dame College</b> <a href='https://en.wikipedia.org/wiki/Notre_Dame_College,_Dhaka'>🌐</a> <br />
                      <small><i>July 2015 - April 2017</i></small><br />
                      <b>Grade</b>: 5.0 (Board General Scholarship) <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            {/* Achievements */}
            <div>
              <h2 className="page-sub-header">
                Achievements
              </h2>
              <div class="task-container columns is-centered is-multiline">
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="trophy">🏆</span> Champion - Blockchain Olympiad Bangladesh</b> <br />
                      <b>BCOLBD</b> <br />
                      <small><i>Feb 2021</i></small><br />
                      {/* <b>Project</b>: ~<br /> */}
                    </div>
                  </div>
                </div>
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="medal">🥇</span> Runner-up - Hack The Verse</b> <br />
                      <b>IITSEC, Dhaka University</b> <br />
                      <small><i>Nov 2020</i></small><br />
                    </div>
                  </div>
                </div>
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="trophy">🏆</span> Champion - Tracthon</b> <br />
                      <b>Brac University Robotics Club, BRACU</b> <br />
                      <small><i>Sep 2020</i></small><br />
                    </div>
                  </div>
                </div>
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="medal">🥇</span> Runner-up - Bracathon 3.0</b> <br />
                      <b>BRAC</b> <br />
                      <small><i>July 2019</i></small><br />
                    </div>
                  </div>
                </div>
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="medal">🥇</span> Runner-up - Hackathon, CSE Fest 2019</b> <br />
                      <b>Dept of CSE, BUET</b> <br />
                      <small><i>Jan 2019</i></small><br />
                    </div>
                  </div>
                </div>
                <div className="column is-two-fifths">
                  <div class="card">
                    <div class="card-content">
                      <b><span role="img" aria-label="trophy">🏆</span> Champion - SDG Hackathon</b> <br />
                      <b>Banglalink</b> <br />
                      <small><i>Dec 2018</i></small><br />
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <br />

        </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}
